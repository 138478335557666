@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Inter;
  src: url(/fonts/Inter-VariableFont_slnt,wght.ttf);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif;
  scrollbar-width: none;
  &::-webkit-scrollbar{
    display: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.background {
  // position: absolute;
  color: white;
  width: 100%;
  background: #16161A;

  // -webkit-mask-attachment: fixed;
  // -webkit-mask: url(/images/bg_smoke.svg) top / cover  no-repeat;
  // mask: url(/images/bg_smoke.svg) top / cover  no-repeat;
  // max-height: 100%;
}

// header {
//   width: 100%;
//   height: 160px; 
// }

// .page {
//   height: calc(100vh - 160px);
// }
.overflow-hidden{
  scrollbar-width: none;
  &::-webkit-scrollbar{
    display: none;
  }
}


