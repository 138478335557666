.btnLogin {
    //'cursor-pointer font-bold mt-4 w-full'
    cursor: pointer;
    font-weight: bold;
    margin-top: 1rem;
    width: 100%;
    background-color: rgba(35,38,47,var(--tw-bg-opacity));
}
.connectWalletLink {
    //"absolute top-0 right-0	outline-none h-8 w-8 flex justify-center items-center"
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 1rem;
    height: 3.5rem;
    background-color: rgba(35,38,47,var(--tw-bg-opacity));
    padding: 1rem 0.5rem;
    border-radius: 0.25rem;
}
.divider {
    margin-top: 1rem;
    padding-top: 1rem;
    border-color: rgba(68,68,68,1);
    border-top-width: 1px;
}
.logo {
    width: 2.5rem;
    margin-right: 1rem;
}
.chevron {
    width: 1.25rem;
    color: rgba(177,181,195,var(--tw-text-opacity));
}
.logoProvider {
    width: 5rem;
}
.providerInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}